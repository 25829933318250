import { DEX } from './dex'

export const dexAddress = {
  [DEX.Daimon]: {
    router: '0xAb30a29168D792c5e6a54E4bcF1Aec926a3b20FA',
    factory: '0x6E906Dc4749642a456907deCB323A0065dC6F26E',
  },
  [DEX.Vonder]: {
    router: '0x54D851C39fE28b2E24e354B5E8c0f09EfC65B51A',
    factory: '0x447DdE468Fb3B185d395D8D43D82D6636d69d481',
  },
  [DEX.Foodcourt]: {
    router: '0xa93F69529826918A38127a76c366cA1445Fc1158',
    factory: '0xeC9c39E283a7956b3EE22816648824b9DF783283',
  },
  [DEX.Baryon]: {
    router: '0x5C93F4B35d3dD97Ef481881aA33d00F76806FdAD',
    factory: '0xf7eEe3A8363731C611A24CdDfCBcaDE9C153Cfe8',
  },
}
