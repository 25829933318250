import { getAddress } from 'ethers/lib/utils'
import { DEX } from './constants/dex'

export const rpcUrl = 'https://rpc.bitkubchain.io'
export const explorerUrl = 'https://www.bkcscan.com/'

export const defaultTokenOptions = [
  { address: '0x67eBD850304c70d983B2d1b93ea79c7CD6c3F6b5', symbol: 'KKUB' },
  { address: '0x7d984C24d2499D840eB3b7016077164e15E5faA6', symbol: 'KUSDT' },
  { address: '0x77071ad51ca93fc90e77BCdECE5aa6F1B40fcb21', symbol: 'KUSDC' },
  { address: '0xED7B8606270295d1b3b60b99c051de4D7D2f7ff2', symbol: 'kDAI' },
  { address: '0x9C04EFD1E9aD51A605eeDcb576159242FF930368', symbol: 'FANS' },
  { address: '0x42A7ECC6D2D2780C2C2daAa8F7f2d2C2557e276b', symbol: 'kLOREM' },
  { address: '0x19dade57B0BBCE7D5E859ba02846820f5c0c2b09', symbol: 'VON' },
  { address: '0x95013Dcb6A561e6C003AED9C43Fb8B64008aA361', symbol: 'LUMI' },
  { address: '0xd3F61df898965c529a942985049E60592C451410', symbol: 'YAKK' },
  { address: '0x8debbb77e8a17cffCfC0C7F1f40308886edD3f9f', symbol: 'YES' },
  { address: '0xDD7847deD760a8e7FB882B4A9B0e990323415ed9', symbol: 'MVP' },
  { address: '0xb2D5a5AEC0C7f2Cac33DcdF6D7B47Ab6Dd403246', symbol: 'META' },
  { address: '0x8E3C2B00eFEBB64a6B775c451197a9dbA1077967', symbol: 'DK' },
  { address: '0xB810bC46B200c8024EE63071691D84f0fd111a9B', symbol: 'KM' },
  { address: '0xf482B77FbE4076b18e49E74ACda79789C84d16cc', symbol: 'SCP' },
  { address: '0x711a189c8D1Eff2438f41c06c083DD1214705ce5', symbol: 'GOLD' },
  { address: '0xb0719390f2B882AbCa0752149bA79301711F6FE0', symbol: 'GAIA' },
  { address: '0x1DC6CB986EF0265D34C04d421867B4e34E37EC50', symbol: 'CRYS' },
  { address: '0x6527d3D38a7Ff4E62f98fE27dd9242a36227FE23', symbol: 'TAO' },
].map((t) => ({ address: getAddress(t.address), symbol: t.symbol }))

const getTokenOptionFromSymbol = (symbol) =>
  defaultTokenOptions.find(
    (token) => token.symbol.toLowerCase() === symbol.toLowerCase()
  )

export const defaultDex = DEX.Daimon
export const defaultBaseToken = getTokenOptionFromSymbol('KKUB')
export const defaultQuoteToken = getTokenOptionFromSymbol('KUSDT')

export const fullNumPastBlock = 4500
export const minNumPastLogsPerStart = 250
export const maxGetPastLogsPerStart = 10
export const timeZoneUTC = 7
export const intervalGetLogs = 7500

export const tokenPairUSD = {
  [getTokenOptionFromSymbol('KKUB').address]: {
    isStable: false,
    baseToken: getTokenOptionFromSymbol('KKUB'),
    quoteToken: getTokenOptionFromSymbol('KUSDT'),
    dex: DEX.Daimon,
  },
  [getTokenOptionFromSymbol('KUSDT').address]: {
    isStable: true,
    baseToken: getTokenOptionFromSymbol('KUSDT'),
  },
  [getTokenOptionFromSymbol('KUSDC').address]: {
    isStable: true,
    baseToken: getTokenOptionFromSymbol('KUSDC'),
  },
  [getTokenOptionFromSymbol('kDAI').address]: {
    isStable: true,
    baseToken: getTokenOptionFromSymbol('kDAI'),
  },
  [getTokenOptionFromSymbol('DK').address]: {
    isStable: false,
    baseToken: getTokenOptionFromSymbol('DK'),
    quoteToken: getTokenOptionFromSymbol('KUSDT'),
    dex: DEX.Daimon,
  },
  [getTokenOptionFromSymbol('GAIA').address]: {
    isStable: false,
    baseToken: getTokenOptionFromSymbol('GAIA'),
    quoteToken: getTokenOptionFromSymbol('KUSDT'),
    dex: DEX.Daimon,
  },
}
